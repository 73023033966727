<template>
  <div class="popup-image">
    <div class="popup-image__wrapper">
      <img class="popup-image__image"
           :src="this.$popup.additions"
           alt/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupImage',
};
</script>
